html {
  height: 100%;
}
body, #root, .App {
  height: 100%;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}