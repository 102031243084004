 .dot-typing {
   position: relative;
   left: -9999px;
   width: 5px;
   height: 5px;
   border-radius: 5px;
   background-color: #9d9d9d;
   color: #9d9d9d;
   box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px 0 0 0;
   animation: dot-typing 1.5s infinite linear;
 }
 
 @keyframes dot-typing {
   0% {
     box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px 0 0 0;
   }
   16.667% {
     box-shadow: 9984px -10px 0 0, 9993px 0 0 0, 10002px 0 0 0;
   }
   33.333% {
     box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px 0 0 0;
   }
   50% {
     box-shadow: 9984px 0 0 0, 9993px -10px 0 0, 10002px 0 0 0;
   }
   66.667% {
     box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px 0 0 0;
   }
   83.333% {
     box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px -10px 0 0;
   }
   100% {
     box-shadow: 9984px 0 0 0, 9993px 0 0 0, 10002px 0 0 0;
   }
 }
 